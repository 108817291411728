// Starting up Alpine
import Alpine from "alpinejs";
import focus from '@alpinejs/focus'

window.Alpine = Alpine;
Alpine.start();
Alpine.plugin(focus)




// Required for isotope to check if images have finished loading to run https://imagesloaded.desandro.com/
require("imagesloaded/imagesloaded.pkgd.min");

